<template>
  <div>
    <div
      class="no-print title font-weight-bold ml-3 mr-3 d-flex align-center justify-space-between flex-wrap"
    >
      <div class="no-print">
        <back-button color="grey" @click="$router.go(-1)" />
        Revisão da terapia
      </div>
      <div class="button-wrapper">
        <v-btn
          class="no-print"
          :height="30"
          :width="260"
          @click="showPatientReport"
        >
          <v-icon class="mr-2">description</v-icon>
          <span>Diagnose - paciente</span>
        </v-btn>
        <v-btn
          class="no-print"
          color="secondary"
          :height="30"
          :width="320"
          @click="nativePrint"
        >
          <v-icon class="mr-2">print</v-icon>
          <span>Diagnóstico - terapeuta</span>
        </v-btn>
      </div>
    </div>
    <div ref="printable">
      <div class="d-flex">
        <v-col cols="12">
          <review-card title="Dados da terapia">
            <v-card-text class="black--text">
              <div class="mb-2">
                <span class="font-weight-bold">Paciente: </span>
                {{ therapy.user.name }}
              </div>
              <div class="mb-2">
                <span class="font-weight-bold">Protocolo: </span>
                {{ therapy.protocol }}
              </div>
              <div class="mb-2">
                <span class="font-weight-bold">Realizada em: </span>
                {{ therapy.createdAtLocal | formatDateTime }}
              </div>
              <div class="mb-2">
                <span class="font-weight-bold">Finalizada em: </span>
                {{ therapy.finishedAt | formatDateTime }}
              </div>
              <div class="mb-2">
                <span class="font-weight-bold">Sincronizada em: </span>
                {{ therapy.createdAt | formatDateTime }}
              </div>
              <div class="my-2">
                <span class="font-weight-bold">
                  Comentários gerais aos terapeutas:
                </span>
                <div v-html="formattedTherapistComments"></div>
              </div>
              <div class="my-2">
                <span class="font-weight-bold">
                  Comentários gerais ao paciente:
                </span>

                <div v-html="formattedPatientComments"></div>
              </div>
            </v-card-text>
          </review-card>
        </v-col>
      </div>

      <div class="d-flex">
        <v-col cols="12">
          <v-card class="elevation-3 rounded-10">
            <v-card-title class="text-h6"> Anamnese </v-card-title>
            <v-divider />
            <v-card-text class="black--text">
              <div class="d-flex flex-column">
                <span class="text-subtitle-1"
                  >Utiliza marca-passo ou qualquer dispositivo que use baterias
                  no corpo?</span
                >
                <span>{{
                  therapy.question.utilizaDispositivoComBateriasNoCorpo
                    ? "Sim "
                    : "Não"
                }}</span>
                <span
                  v-if="therapy.question.utilizaDispositivoComBateriasNoCorpo"
                >
                  {{ therapy.question.qualDispositivoComBateriasNoCorpo }}
                </span>
              </div>

              <v-divider class="mb-4" />

              <div class="d-flex flex-column">
                <span class="text-subtitle-1">Possui histórico de câncer?</span>
                <span>{{
                  therapy.question.possuiHistoricoDeCancer ? "Sim " : "Não"
                }}</span>
                <span v-if="therapy.question.possuiHistoricoDeCancer">
                  {{ therapy.question.descricaoHistoricoDeCancer }}
                </span>
              </div>
              <v-divider class="mb-4" />

              <div class="d-flex flex-column">
                <span class="text-subtitle-1"
                  >Já recebeu quimioterapia e/ou radioterapia?</span
                >
                <span>{{
                  therapy.question.recebeuQuimioterapiaOuRadioterapia
                    ? "Sim "
                    : "Não"
                }}</span>
                <span
                  v-if="therapy.question.recebeuQuimioterapiaOuRadioterapia"
                >
                  {{ therapy.question.quandoQuimioterapiaOuRadioterapia }}
                </span>
              </div>

              <v-divider class="mb-4" />

              <div v-if="therapy.question.mulher" class="d-flex flex-column">
                <span class="text-subtitle-1">Está grávida?</span>
                <span>{{ therapy.question.estaGravida ? "Sim" : "Não" }}</span>
                <span v-if="therapy.question.estaGravida">
                  Tempo (em meses):
                  {{ therapy.question.tempoDeGravidez }}
                </span>

                <v-divider class="mb-4" />

                <div
                  v-if="!therapy.question.estaGravida"
                  class="d-flex flex-column"
                >
                  <span class="text-subtitle-1">Utiliza DIU?</span>
                  <span>{{ therapy.question.possuiDiu ? "Sim" : "Não" }}</span>
                  <span v-if="therapy.question.possuiDiu">
                    Tipo: {{ therapy.question.qualDiu }}
                  </span>
                  <v-divider class="mb-4" />

                  <span class="text-subtitle-1">Risco de estar grávida?</span>
                  <span>{{
                    therapy.question.haChancesDeEstarGravida ? "Sim" : "Não"
                  }}</span>

                  <v-divider class="mb-4" />

                  <span class="text-subtitle-1"
                    >Utiliza métodos contraceptivos?</span
                  >
                  <span>{{
                    therapy.question.utilizaMetodosContraceptivos
                      ? "Sim"
                      : "Não"
                  }}</span>
                  <span v-if="therapy.question.utilizaMetodosContraceptivos">
                    Método contraceptivo:
                    {{ therapy.question.quaisMetodosContraceptivos }}
                  </span>
                  <v-divider class="mb-4" />
                </div>

                <span class="text-subtitle-1"
                  >Possui alguma prótese de silicone?</span
                >
                <span>{{
                  therapy.question.possuiImplanteDeSilicone ? "Sim" : "Não"
                }}</span>
                <v-divider class="mb-4" />
              </div>

              <div class="d-flex flex-column">
                <span class="text-subtitle-1">Queixa principal</span>
                <span>{{
                  getTextOrEmpty(therapy.question.queixaPrincipal)
                }}</span>

                <v-divider class="mb-4" />

                <span class="text-subtitle-1"
                  >Histórico de Doença Atual (HDA)</span
                >
                <span>{{
                  getTextOrEmpty(therapy.question.historicoDeDoencaAtual)
                }}</span>
                <v-divider class="mb-4" />

                <span class="text-subtitle-1">Exames complementares</span>
                <span>{{
                  getTextOrEmpty(therapy.question.examesComplementares)
                }}</span>

                <v-divider class="mb-4" />

                <span class="text-subtitle-1">Estado emocional</span>
                <span>{{
                  getTextOrEmpty(therapy.question.estadoEmocional)
                }}</span>

                <v-divider class="mb-4" />

                <span class="text-subtitle-1"
                  >História Patológica Pregressa (HPP)</span
                >
                <span>{{
                  getTextOrEmpty(therapy.question.historiaPatologicaPregressa)
                }}</span>

                <v-divider class="mb-4" />

                <span class="text-subtitle-1">História Fisiológica</span>
                <span>{{
                  getTextOrEmpty(therapy.question.historiaFisiologica)
                }}</span>

                <v-divider class="mb-4" />

                <span class="text-subtitle-1">História Familiar</span>
                <span>{{
                  getTextOrEmpty(therapy.question.historiaFamiliar)
                }}</span>

                <v-divider class="mb-4" />

                <span class="text-subtitle-1">História Social</span>
                <span>{{
                  getTextOrEmpty(therapy.question.historiaSocial)
                }}</span>

                <v-divider class="mb-4" />

                <span class="text-subtitle-1">Revisão dos Sintomas</span>
                <span>{{
                  getTextOrEmpty(therapy.question.revisaoDosSintomas)
                }}</span>
              </div>

              <v-divider class="mb-4" />

              <div class="d-flex flex-column align-center no-print">
                <span class="text-subtitle-1">Termo de consentimento</span>
                <a v-if="attachmentUrl" :href="attachmentUrl" target="_blank"
                  >(clique para visualizar)</a
                >
                <span v-else>Nenhum termo de consentimento anexado</span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </div>
      <div class="d-flex flex-wrap">
        <v-col v-if="hasPares" :md="expanded ? 12 : 6" cols="12">
          <pares :pares="therapy.diagnose.pares" :expanded="expanded" />
        </v-col>

        <v-col v-if="hasTumoralPhenomena" :md="expanded ? 12 : 6" cols="12">
          <tumoral-phenomena
            :tumoral-phenomena="therapy.diagnose.tumoralPhenomena"
          />
        </v-col>
        <v-col v-if="hasVascularPhenomena" :md="expanded ? 12 : 6" cols="12">
          <vascular-phenomena
            :vascular-phenomena="therapy.diagnose.vascularPhenomena"
          />
        </v-col>
        <v-col v-if="hasPotentialSpaces" :md="expanded ? 12 : 6" cols="12">
          <potential-spaces
            :potential-spaces="therapy.diagnose.potentialSpaces"
          />
        </v-col>
        <v-col v-if="hasFlowTypes" :md="expanded ? 12 : 6" cols="12">
          <flow-types
            :flow-types="therapy.diagnose.flowTypes"
            :expanded="expanded"
          />
        </v-col>
        <v-col v-if="hasOligoelements" :md="expanded ? 12 : 6" cols="12">
          <oligoelements
            :oligoelements="therapy.diagnose.oligoelements"
            :expanded="expanded"
          />
        </v-col>
        <v-col v-if="hasChakras" :md="expanded ? 12 : 6" cols="12">
          <chakras
            :chakras="therapy.diagnose.chakras"
            :hide-names-chakras="therapy.diagnose.hideNamesChakras"
            :expanded="expanded"
          />
        </v-col>
        <v-col v-if="hasChromosomes" :md="expanded ? 12 : 6" cols="12">
          <chromosomes
            :chromosomes="therapy.diagnose.chromosomes"
            :expanded="expanded"
          />
        </v-col>
        <v-col
          v-if="hasComplementaryTherapies"
          :md="expanded ? 12 : 6"
          cols="12"
        >
          <complementary-therapies
            :complementary-therapies="therapy.diagnose.complementarTherapies"
            :expanded="expanded"
          />
        </v-col>
      </div>
    </div>
  </div>
</template>

<script>
import {
  findOneTherapist,
  getTherapyAttachments,
} from "@/services/external/therapies-service";
import html2pdf from "html2pdf.js";
import printJS from "print-js"; // <-- Import print.js library
import DOMPurify from "dompurify";

export default {
  components: {
    Pares: () =>
      import(
        "@/domains/therapy/review/therapist/presentation/components/Pares"
      ),
    TumoralPhenomena: () =>
      import(
        "@/domains/therapy/review/therapist/presentation/components/TumoralPhenomena"
      ),
    VascularPhenomena: () =>
      import(
        "@/domains/therapy/review/therapist/presentation/components/VascularPhenomena"
      ),
    PotentialSpaces: () =>
      import(
        "@/domains/therapy/review/therapist/presentation/components/PotentialSpaces"
      ),
    FlowTypes: () =>
      import(
        "@/domains/therapy/review/therapist/presentation/components/FlowTypes"
      ),
    Oligoelements: () =>
      import(
        "@/domains/therapy/review/therapist/presentation/components/Oligoelements"
      ),
    Chakras: () =>
      import(
        "@/domains/therapy/review/therapist/presentation/components/Chakras"
      ),
    Chromosomes: () =>
      import(
        "@/domains/therapy/review/therapist/presentation/components/Chromosomes"
      ),
    ComplementaryTherapies: () =>
      import(
        "@/domains/therapy/review/therapist/presentation/components/ComplementaryTherapies"
      ),
  },
  data: () => ({
    therapy: {
      diagnose: {
        flowTypes: [],
        potentialSpaces: [],
        tumoralPhenomena: [],
        vascularPhenomena: [],
        pares: [],
        complementaryTherapies: [],
      },
      user: {},
      question: {},
    },
    expanded: false,
    attachmentUrl: "",
  }),
  computed: {
    hasPares() {
      return (
        this.therapy.diagnose.pares && this.therapy.diagnose.pares.length > 0
      );
    },
    hasTumoralPhenomena() {
      return (
        this.therapy.diagnose.tumoralPhenomena &&
        this.therapy.diagnose.tumoralPhenomena.length > 0
      );
    },
    hasVascularPhenomena() {
      return (
        this.therapy.diagnose.vascularPhenomena &&
        this.therapy.diagnose.vascularPhenomena.length > 0
      );
    },
    hasPotentialSpaces() {
      return (
        this.therapy.diagnose.potentialSpaces &&
        this.therapy.diagnose.potentialSpaces.length > 0
      );
    },
    hasFlowTypes() {
      return (
        this.therapy.diagnose.flowTypes &&
        this.therapy.diagnose.flowTypes.length > 0
      );
    },
    hasOligoelements() {
      return (
        this.therapy.diagnose.oligoelements &&
        this.therapy.diagnose.oligoelements.length > 0
      );
    },
    hasChakras() {
      return (
        this.therapy.diagnose.chakras &&
        this.therapy.diagnose.chakras.length > 0
      );
    },
    hasChromosomes() {
      return (
        this.therapy.diagnose.chromosomes &&
        this.therapy.diagnose.chromosomes.length > 0
      );
    },
    hasComplementaryTherapies() {
      return (
        this.therapy.diagnose.complementarTherapies &&
        this.therapy.diagnose.complementarTherapies.length > 0
      );
    },
    isExpanded() {
      return this.expanded;
    },
    formattedTherapistComments() {
      return DOMPurify.sanitize(
        this.therapy.diagnose.therapistComments.replace(/\n/g, "<br />")
      );
    },
    formattedPatientComments() {
      return DOMPurify.sanitize(
        this.therapy.diagnose.patientComments.replace(/\n/g, "<br />")
      );
    },
  },
  created() {
    this.loadTherapy();
    this.loadTherapyAttachments();
  },
  methods: {
    openAttachment() {
      console.log(this.attachmentUrl);
      window.open(this.attachmentUrl);
    },
    close() {
      this.$emit("close");
    },
    closeDrawer() {
      this.$store.commit("settings/setDrawer", false);
    },
    async loadTherapy() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await findOneTherapist(this.$route.query.protocol);
        console.log(data);
        this.therapy = data;
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },

    async loadTherapyAttachments() {
      try {
        const { data } = await getTherapyAttachments(
          this.$route.query.protocol
        );
        const termo = data.find(
          (item) => item.attachmentType === "signed_consent_term"
        );
        if (termo) {
          this.attachmentUrl = termo.url;
        }
      } catch (error) {
        this.$errorHandler(error);
      }
    },

    async printWithInlineStyles() {
      this.expanded = true;

      // Clone the element
      await this.$nextTick(); // wait for the DOM update

      setTimeout(async () => {
        const originalElement = this.$refs.printable;
        // originalElement.style.width = "800px";
        const clonedElement = originalElement.cloneNode(true);

        // Apply inline styles
        this.applyInlineStyles(clonedElement, originalElement);
        // clonedElement.style.maxWidth = "500px";
        // scale down the element to fit on the page

        // Append to the body (make sure it's not visible)
        // clonedElement.style.display = "none";
        document.body.appendChild(clonedElement);
        //  clonedElement.style.width = "700px";
        const childElements = clonedElement.querySelectorAll("*"); // This selects all descendants
        childElements.forEach((child) => {
          //   child.style.maxWidth = "700px";
          child.style.fontSize = "10px";
        });

        // Print the content
        const opt = {
          margin: [0, 0, 0, 0],
          filename: "review.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
          pagebreak: {
            before: ".breakBeforeClass",
            after: ".breakAfterClass",
          },
        };
        //   await html2pdf().from(clonedElement).set(opt).save();

        printJS({
          printable: clonedElement,
          type: "html",
          css: "https://fonts.googleapis.com/css?family=Roboto&display=swap",
          scanStyles: false,
          style: `

          @page {
            size: A4 landscape;
            margin: 0;
          }
          .breakBeforeClass {
            page-break-before: always;
          }
          .breakAfterClass {
            page-break-after: always;
          }
          `,
        });

        // Remove the cloned element after printing
        //  document.body.removeChild(clonedElement);
        //  this.expanded = false;
      }, 1000);
    },
    applyInlineStyles(element, computedElement) {
      const computedStyles = window.getComputedStyle(computedElement);

      Array.from(computedStyles).forEach((stylePropertyName) => {
        const value = computedStyles.getPropertyValue(stylePropertyName);
        element.style[stylePropertyName] = value;
      });

      // Recurse for child elements
      for (let i = 0; i < element.children.length; i++) {
        this.applyInlineStyles(
          element.children[i],
          computedElement.children[i]
        );
      }
    },
    async nativePrint() {
      this.expanded = true;
      this.closeDrawer();
      await this.$nextTick();
      setTimeout(() => {
        window.print();
      }, 2000);
    },
    showPatientReport() {
      this.$router.push({
        name: "userReview",
        query: { id: this.therapy.id },
      });
    },

    getTextOrEmpty(text) {
      return text || "Nenhuma informação preenchida";
    },
  },
};
</script>

<style>
.button-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
